.Card_wrap {
  box-shadow: 4px 3px 20px 0 rgba(0, 0, 0, 0.1);
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap;
  -ms-grid-column-span: 3;
  grid-column: span 3;
}

@media (max-width: 1024px) {
  .Card_wrap {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-align: center;
    align-items: center;
    -ms-grid-column: 1;
    -ms-grid-column-span: 12;
    grid-column: 1/13;
  }
}

.Card_wrap.Card_wide {
  -ms-grid-column-span: 4;
  grid-column: span 4;
}

@media (max-width: 1024px) {
  .Card_wrap.Card_wide {
    -ms-flex-flow: column nowrap;
    flex-flow: column nowrap;
    -ms-grid-column: 1;
    -ms-grid-column-span: 12;
    grid-column: 1/13;
  }
}

.Card_browse.Card_wrap {
  margin-bottom: 40px;
  box-shadow: none;
}

.Card_previewImage {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  display: block;
  position: relative;
  width: 100%;
  height: 340px;
  background-position: 50%;
  background-size: cover;
  border-bottom: 2px solid;
  -o-border-image: -o-linear-gradient(
      left,
      var(--consumer-pink),
      var(--consumer-orange)
    )
    1;
  border-image: linear-gradient(
      90deg,
      var(--consumer-pink),
      var(--consumer-orange)
    )
    1;
}

@media (max-width: 1024px) {
  .Card_previewImage {
    height: 110px;
    border-bottom: none;
    -ms-flex: 0 0 33%;
    flex: 0 0 33%;
  }
}

.Card_wide .Card_previewImage {
  height: 250px;
}

@media (max-width: 1024px) {
  .Card_wide .Card_previewImage {
    width: 100%;
    height: 200px;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }
}

.Card_browse .Card_previewImage {
  height: 200px;
  border-bottom: none;
}

.Card_hoverOverlay {
  box-sizing: border-box;
  opacity: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  padding: 15px 18px;
  -o-transition: opacity 0.15s ease-in;
  transition: opacity 0.15s ease-in;
  background-image: -o-linear-gradient(
    48deg,
    rgba(208, 16, 109, 0.75),
    rgba(236, 83, 56, 0.75) 75%,
    rgba(254, 160, 3, 0.75)
  );
  background-image: linear-gradient(
    42deg,
    rgba(208, 16, 109, 0.75),
    rgba(236, 83, 56, 0.75) 75%,
    rgba(254, 160, 3, 0.75)
  );
}

.Card_previewImage:focus .Card_hoverOverlay,
.Card_previewImage:hover .Card_hoverOverlay {
  opacity: 1;
}

.Card_overlayBorder {
  border: 1px solid #fff;
  height: 100%;
}

.Card_overlayButton {
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.Card_info {
  -ms-flex: auto;
  flex: auto;
}

@media (max-width: 1024px) {
  .Card_info {
    -ms-flex: 0 0 66%;
    flex: 0 0 66%;
  }
  .Card_wide .Card_info {
    -ms-flex-item-align: stretch;
    -ms-grid-row-align: stretch;
    align-self: stretch;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }
}

.Card_info p {
  font-size: 14px;
  line-height: 22px;
  margin: 0 20px;
}

@media (max-width: 1024px) {
  .Card_info p {
    margin-bottom: 10px;
  }
}

.Card_badge {
  text-transform: uppercase;
  color: var(--charcoal-grey);
  font-size: 12px;
  font-weight: 500;
  margin: 20px;
  margin-bottom: 15px;
}

.Card_badge:before {
  font-family: Font Awesome\ 5 Free;
  font-weight: 900;
  color: var(--charcoal-grey);
  width: 10px;
  height: 10px;
  margin-right: 10px;
}

@media (max-width: 1024px) {
  .Card_badge {
    font-size: 11px;
    margin: 10px 25px;
  }
}

.Card_featured:before {
  content: "\f02e";
}

.Card_editor:before {
  content: "\f005";
}

.Card_title {
  box-sizing: border-box;
  font-size: 18px;
  font-weight: 300;
  margin: 0 20px;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  padding-top: 20px;
  padding-bottom: 20px;
}

.Card_badge ~ .Card_title {
  padding-top: 0;
}

.Card_title + p {
  margin-top: -20px;
}

@media (max-width: 1024px) {
  .Card_title {
    font-size: 16px;
    height: auto;
    padding-top: 0;
    padding-bottom: 0;
  }
  .Card_title + p {
    margin-top: 0;
  }
}

@media (max-width: 1024px) {
  .Card_wide .Card_title {
    font-size: 16px;
    height: auto;
    margin: 0 25px 10px;
  }
}

@media (max-width: 1024px) {
  .Card_meta {
    display: none;
  }
  .Card_wide .Card_meta {
    display: block;
    -ms-flex-item-align: stretch;
    -ms-grid-row-align: stretch;
    align-self: stretch;
  }
}

.Card_home.Card_wide.Card_wrap {
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -ms-grid-column: 1;
  -ms-grid-column-span: 12;
  grid-column: 1/13;
  box-shadow: none;
}

.Card_home.Card_wide .Card_previewImage {
  -ms-flex-order: 2;
  order: 2;
  height: 450px;
  -ms-flex: 0 0 66.666%;
  flex: 0 0 66.666%;
  border-bottom: none;
}

.Card_home.Card_wide .Card_info {
  -ms-flex: 0 0 33.333%;
  flex: 0 0 33.333%;
  -ms-flex-line-pack: center;
  align-content: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

.Card_home.Card_wide .Card_meta {
  display: none;
}

@media (max-width: 1024px) {
  .Card_browse.Card_wrap,
  .Card_home.Card_wrap {
    -ms-flex-flow: column nowrap;
    flex-flow: column nowrap;
    margin-left: 5px;
    margin-right: 5px;
    -ms-flex-align: stretch;
    align-items: stretch;
    box-shadow: 4px 3px 20px 0 rgba(0, 0, 0, 0.1);
    position: relative;
  }
  .Card_browse.Card_wrap {
    box-shadow: none;
  }
  .Card_browse .Card_previewImage,
  .Card_home .Card_previewImage {
    height: 190px;
    border-bottom: 2px solid;
    -ms-flex: auto;
    flex: auto;
  }
  .Card_browse .Card_info,
  .Card_home .Card_info {
    -ms-flex: auto;
    flex: auto;
  }
  .Card_browse .Card_info p,
  .Card_home .Card_info p {
    margin: 0 15px 10px;
  }
  .Card_browse .Card_title,
  .Card_home .Card_title {
    margin: 15px;
    -ms-flex: auto;
    flex: auto;
  }
  .Card_browse .Card_meta,
  .Card_home .Card_meta {
    display: block;
    -ms-flex: auto;
    flex: auto;
  }
  .Card_browse .Card_meta > *,
  .Card_home .Card_meta > * {
    padding: 15px;
  }
  .Card_home.Card_wide.Card_wrap {
    -ms-grid-column: 1;
    -ms-grid-column-span: 12;
    grid-column: 1/13;
    margin-left: 0;
    margin-right: 0;
    box-shadow: none;
    -ms-flex-flow: column nowrap;
    flex-flow: column nowrap;
  }
  .Card_home.Card_wide .Card_previewImage {
    -ms-flex-order: 0;
    order: 0;
    width: 100%;
    height: 380px;
    -ms-flex: auto;
    flex: auto;
  }
  .Card_home.Card_wide .Card_info {
    -ms-flex-item-align: stretch;
    -ms-grid-row-align: stretch;
    align-self: stretch;
    -ms-flex: auto;
    flex: auto;
  }
  .Card_home.Card_wide .Card_title {
    font-size: 16px;
    height: auto;
    margin: 0 25px 10px;
  }
  .Card_home.Card_wide .Card_meta {
    display: none;
  }
}
